import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useComponentSize, useWindowSize } from 'react-use-size';

import DiscardModal from '@/client/components/admin/create/shared/overlay/DiscardModal';
import ProductAccessForm from './ProductAccessForm';
import ProductGeneralDetailsForm from './ProductGeneralDetailsForm';
import ProductSettingsForm from './ProductSettingsForm';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PublishModal from '@/client/components/admin/create/shared/overlay/PublishModal';
import CreatePublishButton from '@/client/components/admin/create/shared/buttons/CreatePublishButton';

interface EditProductFormProps {
  saveFn: () => Promise<void>;
}

export default function EditProductForm({ saveFn }: EditProductFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { discardModalIsOpen, setDiscardModalIsOpen, setPublishModalIsOpen, setContentType } =
    useCreateStore();
  const { ref: containerRef, width } = useComponentSize();
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    setContentType('product');
  }, []);

  return (
    <Stack>
      <Box ref={containerRef}>
        <Box marginX={10} marginTop="72px" overflowY="auto">
          <Stack spacing={6}>
            <Text variant="createHeading" fontSize="28px">
              {t('authoring.product.createProduct')}
            </Text>
            <ProductGeneralDetailsForm />
            <Divider variant="create" />
            <ProductSettingsForm />
            <Divider variant="create" />
            <ProductAccessForm />
          </Stack>
          <DiscardModal
            isOpen={discardModalIsOpen}
            onDiscard={() => {
              setDiscardModalIsOpen(false);
              navigate('/overview/products');
            }}
            onClose={() => {
              setDiscardModalIsOpen(false);
            }}
          />
          <PublishModal
            onPublish={async () => {
              setPublishModalIsOpen(false);
              await saveFn();
              navigate('/overview/products');
            }}
          />
        </Box>
      </Box>
      <Flex
        zIndex={888}
        position="fixed"
        background="white"
        borderTop="1px solid"
        borderTopColor="warmNeutral.200"
        left={windowWidth - width}
        right={0}
        bottom={0}
        paddingY={4}
        paddingX={18}
        justifyContent="space-between"
      >
        <Button
          variant="createCancel"
          onClick={() => {
            setDiscardModalIsOpen(true);
          }}
        >
          {t('authoring.cancel')}
        </Button>

        <CreatePublishButton onClick={() => setPublishModalIsOpen(true)} />
      </Flex>
    </Stack>
  );
}
