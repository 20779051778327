import { BiCollapse, BiExpand } from 'react-icons/bi';
import { Box, Button, Flex, Icon } from '@chakra-ui/react';

import type { ReactElement } from 'react';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use-size';

interface CreateLayoutProps {
  previewElement: ReactElement;
  formElement: ReactElement;
}

export default function CreateLayout({ previewElement, formElement }: CreateLayoutProps) {
  const { t } = useTranslation();
  const { previewFullscreen, setPreviewFullscreen, contentType } = useCreateStore();
  const { height } = useWindowSize();

  return (
    <Flex marginTop={-2}>
      <Box
        width={previewFullscreen ? 'full' : '40%'}
        backgroundImage={
          contentType === 'product'
            ? '#FFFFFF'
            : "url('/public/images/live-preview-background.jpg')"
        }
        backgroundColor={contentType === 'product' ? '#FFFFFF' : 'brand.mist'}
        backgroundClip="padding-box"
        overflowY="auto"
        paddingX={previewFullscreen ? 0 : '16px'}
        paddingY={previewFullscreen ? 0 : '16px'}
        maxHeight={height - 50}
        height={height - 50}
        position="sticky"
        top={50}
        transition="ease 0.5s"
      >
        <Button
          variant="createCancel"
          zIndex={9999}
          position="absolute"
          top={6}
          right={6}
          leftIcon={<Icon as={previewFullscreen ? BiCollapse : BiExpand} />}
          onClick={() => setPreviewFullscreen(!previewFullscreen)}
          boxShadow={contentType === 'product' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : ''}
        >
          {previewFullscreen
            ? t('authoring.livePreview.returnToEditing')
            : t('authoring.livePreview.preview')}
        </Button>

        {previewElement}
      </Box>

      <Box
        width="60%"
        borderLeft="1px solid"
        borderLeftColor="warmNeutral.200"
        backgroundColor="warmNeutral.0"
        display={previewFullscreen ? 'none' : 'unset'}
      >
        {formElement}
      </Box>
    </Flex>
  );
}
