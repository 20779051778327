import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useAuthStore } from '@/client/services/state/authStore';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useToastStore } from '@/client/services/state/toastStore';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import {
  ContentDetails,
  Product,
  ProductContent,
  ProductCreateInput,
  ProductLicensingType,
  ProductType,
  ProductUpdateInput,
} from '@/client/services/api/graphql/gql/graphql';
import { useGetProduct } from '@/client/services/hooks/content/products/useGetProduct';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import { useEffect } from 'react';
import CreateLayout from '@/client/components/admin/create/shared/layout/CreateLayout';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import EditProductForm from '@/client/components/admin/content-creation/products/EditProductForm';
import typeMap from '@/client/utils/content/typeMap';
import ProductAuthoringLivePreview from '@/client/components/admin/create/content/ProductAuthoringLivePreview';

export default function ProductEditor() {
  const productDefaultValues: ProductUpdateInput = {
    title: '',
    description: '',
    currency: 'USD',
    contentList: [],
    images: [],
    licensingType: ProductLicensingType.Subscription,
    price: 0,
    productType: ProductType.ContentList,
    published: false,
    subscriptionDuration: null,
  };

  const { t } = useTranslation();
  useDocumentTitle(t('products.edit'));
  const { productId } = useParams();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { setToast } = useToastStore();
  // const [imageUploading, setImageUploading] = useState<boolean>(false);

  if (!company.feature_flags.enable_ecommerce) {
    setToast({
      show: true,
      status: 'error',
      title: 'E-Commerce is unavailable for the company.',
    });
    navigate('/explore');
  }

  const { isAllowed } = useAccessHelper();
  useDocumentTitle(t('authoring.product.documentTitle'));

  const allowance = {
    create_product: isAllowed(
      ['admin', 'content', 'all_create'],
      [['admin', 'content', 'products_create']],
      ['admin', 'creator', 'collaborator'],
    ),
    edit_product: isAllowed(
      ['admin', 'content', 'all_edit'],
      [
        ['admin', 'content', 'products_edit'],
        ['admin', 'content', 'my_content_edit'],
      ],
      ['admin', 'creator', 'collaborator'],
    ),
  };

  if ((productId && !allowance.edit_product) || (!productId && !allowance.create_product)) {
    return <Navigate to="/explore" replace />;
  }

  const methods = useForm<ProductUpdateInput | Product>({
    values: productDefaultValues,
    mode: 'onBlur',
  });

  const { getValues, setValue, reset } = methods;
  const { data, isError } = useGetProduct(productId);
  if (isError) return <ErrorAlert title={t('products.unableToGet')} />;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const formatPriceForSave = (price: string | number): number => {
    // Turn strings from NumberInputs into actual floats/numbers
    if (typeof price === 'string') {
      return parseFloat(price);
    }
    return price;
  };

  const formatContentListForSave = (list: ContentDetails[]): ProductContent[] => {
    if (!list?.length) {
      return [];
    }

    return list.map((content: ContentDetails) => ({
      entity: content.id,
      // @ts-ignore
      entityType: typeMap[content.type],
    }));
  };

  const showFieldsErrors = (fields: string[]) => {
    setToast({
      show: true,
      status: 'error',
      title: t('products.save.failure') + fields.join(', '),
    });
  };

  const getInvalidDraftFields = (body: ProductUpdateInput): string[] => {
    const invalidFields: string[] = [];
    if (!body.title) {
      invalidFields.push(t('global.forms.labels_title'));
    }
    if (!body.description) {
      invalidFields.push(t('global.forms.labels_description'));
    }
    if (!body.productType) {
      invalidFields.push(t('overviewProducts.productType'));
    }
    if (!body.licensingType) {
      invalidFields.push(t('products.licensingType'));
    }
    if (!body.price) {
      invalidFields.push(t('overviewProducts.price'))
    }
    if (!body.subscriptionDuration) {
      invalidFields.push(t('products.subscriptionDuration'))
    }
    return invalidFields;
  };

  const getInvalidPublishFields = (body: ProductUpdateInput) => {
    const invalidFields = [];
    const { contentList, subscriptionDuration, images, price } = body;

    if (contentList && contentList.length < 1) {
      invalidFields.push(t('products.contentList'));
    }
    if (!subscriptionDuration) {
      invalidFields.push(t('products.subscriptionDuration'));
    }
    if (!price) {
      invalidFields.push(t('overviewProducts.price'));
    }
    if (!images || !images[0]) {
      invalidFields.push(t('global.forms.labels_image'));
    }
    return invalidFields;
  };

  const publish = async () => {
    // leaving this more modular for now in case we want to add every specific field like we have in other places in the (old) app
    let returnedProduct;
    const formValues: any = getValues();
    // currently not using unused properties below, but may at some point
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { price, contentListDetails, licensingType, productType, currency, updatedAt } = formValues;
    try {
      if (productId) {
        const updateBody: ProductUpdateInput = {
          published: true,
          price: formatPriceForSave(price),
          contentList: formatContentListForSave(contentListDetails),
          licensingType: ProductLicensingType.Subscription,
          productType: ProductType.ContentList,
          currency: 'USD',
          ...formValues,
        };
        // Check body
        const invalidFields = getInvalidPublishFields(updateBody).concat(
          getInvalidDraftFields(updateBody),
        );
        if (invalidFields.length > 0) {
          showFieldsErrors(invalidFields);
          return;
        }
        returnedProduct = await ProductsService.updateProduct(productId, updateBody);
      } else {
        const createBody: ProductCreateInput = {
          title: formValues.title,
          description: formValues.description,
          price: formatPriceForSave(formValues.price),
          contentList: formatContentListForSave(formValues.contentListDetails),
          images: formValues.images,
          subscriptionDuration: formValues.subscriptionDuration,
          productType: ProductType.ContentList,
          licensingType: ProductLicensingType.Subscription,
          published: true,
        };
        returnedProduct = await ProductsService.createProduct(createBody);
      }
      setToast({
        show: true,
        status: 'success',
        title: t('products.save.success'),
      });
      if (!productId) {
        navigate(
          `/admin/edit/products/${
            returnedProduct.createProduct?.id || returnedProduct.updateProduct?.id
          }`,
        );
      }
    } catch (e: any) {
      setToast({
        show: true,
        status: 'error',
        title: t('products.edit.defaultErrorMessage'),
      });
      console.error(e);
    }
  };

  const saveDraft = async () => {
    /**
     * Minimum fields:
     * title
     * description
     * productType (hardcoded)
     * licensingType (hardcoded)
     */
    let returnedProduct;
    const formValues: any = getValues();
    // Already published should not be allowed to then remove other fields
    const isPublished = !!formValues.published;
    if (isPublished) {
      publish();
      return;
    }
    const invalidFields = getInvalidDraftFields(formValues);
    if (invalidFields.length > 0) {
      showFieldsErrors(invalidFields);
      return;
    }
    try {
      const body: ProductCreateInput = {
        title: formValues.title,
        description: formValues.description,
        licensingType: ProductLicensingType.Subscription,
        productType: ProductType.ContentList,
        currency: 'USD',
        images: formValues.images || [],
        price: formatPriceForSave(formValues.price),
        contentList: formatContentListForSave(formValues.contentListDetails),
        subscriptionDuration: formValues.subscriptionDuration || null,
      };

      if (productId) {
        returnedProduct = await ProductsService.updateProduct(productId, body);
      } else {
        returnedProduct = await ProductsService.createProduct(body);
      }

      setToast({
        show: true,
        status: 'success',
        title: t('products.save.success'),
      });

      if (!productId) {
        navigate(
          `/admin/edit/products/${
            returnedProduct.createProduct?.id || returnedProduct.updateProduct?.id
          }`,
        );
      }
    } catch (e: any) {
      setToast({
        show: true,
        status: 'error',
        title: t('products.edit.defaultErrorMessage'),
      });
      console.error(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <CreateLayout
          previewElement={
            <ProductAuthoringLivePreview />
          }
          formElement={<EditProductForm saveFn={saveDraft} />}
        />
      </form>
    </FormProvider>
  );
}
