import { graphql } from '../gql/gql';
// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const listProducts: any = graphql(`
  query Products($page: Int!, $pageSize: Int!, $includeTotalCount: Boolean!, $title: StringQueryOperatorArgs, $id: IDQueryOperatorArgs, $ordering: [OrderBy!]) {
    products(page: $page, pageSize: $pageSize, includeTotalCount: $includeTotalCount, title: $title, id: $id, ordering: $ordering) {
      edges {
        node {
          title
          description
          currency
          id
          images
          licensingType
          price
          productType
          updatedAt
          createdAt
          published
          subscriptionDuration
          purchaseCount
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
  }
`);

export const getProduct: any = graphql(`
  query Product($id: String!) {
    product(id: $id) {
      id
      title
      description
      currency
      images
      price
      licensingType
      productType
      published
      subscriptionDuration
      contentListDetails {
        id
        name
        title
        displayName
        type
        image
      }
    }
  }
`)

export const listPurchases: any = graphql(`
  query PurchasesContainer(
    $includeTotalCount: Boolean!
    $user: IDQueryOperatorArgs
    $id: IDQueryOperatorArgs
    $product: IDQueryOperatorArgs
    $subscriptionActive: Boolean
    $purchaseConfirmedDate: DateQueryOperatorArgs
    $ordering: [OrderBy!]
    $page: Int!
    $pageSize: Int!
  ) {
    purchases(
      includeTotalCount: $includeTotalCount
      user: $user
      id: $id
      product: $product
      subscriptionActive: $subscriptionActive
      purchaseConfirmedDate: $purchaseConfirmedDate
      ordering: $ordering
      page: $page
      pageSize: $pageSize
    ) {
      edges {
        node {
          id
          purchaseInitiatedDate
          purchaseConfirmedDate
          nextScheduledPayment
          paymentAttempts {
            currency
            paymentMethod
            paymentSettledDate
            price
            successful
          }
          product
          productDetails {
            title
            description
            currency
            id
            images
            licensingType
            price
            productType
            subscriptionDuration
          }
          cardDetails {
            brand
            lastFour
          }
          subscriptionActive
          recurring
          user
          userName
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
  }
`);

export const createProduct: any = graphql(`
  mutation CreateProduct(
    $title: String!,
    $description: String!,
    $licensingType: ProductLicensingType!,
    $currency: String,
    $price: Float!,
    $subscriptionDuration: ProductSubscriptionDuration!,
    $contentList: [ProductContentInput!],
    $productType: ProductType!,
    $images: [String!],
    $published: Boolean,
  ) {
    createProduct(
      create: {
        title: $title,
        description: $description,
        licensingType: $licensingType,
        currency: $currency,
        price: $price,
        subscriptionDuration: $subscriptionDuration
        contentList: $contentList,
        productType: $productType,
        images: $images,
        published: $published,
      }
    ) {
      id
    }
  }
`);

export const updateProduct: any = graphql(`
  mutation UpdateProduct(
    $id: String!,
    $title: String!,
    $description: String!,
    $licensingType: ProductLicensingType!,
    $currency: String,
    $price: Float!,
    $contentList: [ProductContentInput!],
    $productType: ProductType!,
    $images: [String!],
    $published: Boolean,
    $subscriptionDuration: ProductSubscriptionDuration!,
  ) {
    updateProduct(
      id: $id, 
      update: {
        title: $title,
        description: $description,
        licensingType: $licensingType,
        currency: $currency,
        price: $price,
        contentList: $contentList,
        productType: $productType,
        images: $images,
        published: $published,
        subscriptionDuration: $subscriptionDuration
      }
    ) {
      id
    }
  }
`);

export const archiveProduct: any = graphql(`
  mutation ArchiveProduct(
    $id: String!,
    $archive: Boolean
  ) {
    archiveProduct(
      id: $id, 
      archiveInput: {
        archive: $archive
    }
  ) {
      id
    }
  }
`);

export const productReportData: any = graphql(`
  query ProductReportDataContainer(
    $dateFilter: DateQueryOperatorArgs!,
    $type: String!
  ) {
    productReport(
      dateFilter: $dateFilter,
      type: $type
    ) {
      edges {
        node {
          permanent
          subscription
        }
      }
    }
  }
`);

export const getPurchaserPayments: any = graphql(`
  query Payments {
    payments {
      productId
      stripeProductId
      productTitle
      paymentMethod
      paymentType
      paymentDate
      paymentTotal
      invoiceUrl
    }
  }
`);